/* General styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: rgb(255, 252, 252);
    padding: 5px;
    border-radius: 2px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #ff0000;
    color: white;
    border: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
}

/* Media query for screen sizes 1366x768 */
@media (max-width: 1366px) {
    .modal-content {
        width: 80%;
        font-size: 0.8rem;
        padding: 5px;
     
     
    }
}

/* Media query for small mobile devices */
@media (max-width: 480px) {
    .modal-content {
        width: 95%;
        max-width: 90%;
        padding: 5px;
        font-size: 0.7rem;
        
        
    }

    .close-button {
        width: 24px;
        height: 24px;
        font-size: 10px;
        
    }
}
